header {
  width: 100%;
  height: 70px;
  background: rgb(240, 240, 240);
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: block;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(224, 224, 224);
  box-shadow: 0px 1px 5px 0px rgba(130, 130, 130, 0.75);
}

.entrada {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0%;
  right:0%;
  cursor:default;
  background-color: red;
  z-index: 1000;
}

.isotipo {
  width: 200px;
  height: auto;
}

.marca {
  height: 70px;
  display: inline-flex;
  font-size: 2.2rem;
  flex-direction: column;
  justify-content: center;
}

.marca a {
  line-height:0%;
}

h1 {
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: 2.5rem;
}

.burger {
  height: 70px;
  float: right;
  font-size: 1.5rem;
  cursor: pointer;
  padding-right: 10px;
  transition: color 0.5s;
  display: flex;
  align-items: center;
}

.burger :hover {
  color: #29D579;
  transition: color 0.5s;
}

.menu {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(240, 240, 240);
  transition: all 0.5s;
  transform: translateX(200%);
}
.fold {
  transform: translateX(0%);
}
nav {
  width: 100%;
  display: block;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  text-align: center;
}

.menunav {
  vertical-align: middle;
}

ul {
  list-style: none;
}

li a {
  color: #333;
  transition: color 0.5s;
  height: 70px;
  line-height: 70px;
}

li a:hover {
  cursor: pointer;
  color: #29D579;
  transition: color 0.5s;
}

a li:hover {
  cursor: pointer;
  color: #29D579;
  transition: color 0.5s;
}



.marca a {
  cursor: pointer;
  color: #333333;
}

a:link, a:visited, a:hover, a:focus, a:active{
  color: none;
  text-decoration: none;
}


svg#bondi2 {
  width: 43px;
  height: 43px;
  fill: #333;
  padding: 2px;
  margin-left: 10px;
}


.rela {
  position: relative
}

.abso {
  display: none;
}

.abso2 {
  position: absolute;
  display: block;
  top: 33%;
  right: 40px;
}

.vivo {
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: red;
  padding: 5px;
  border-radius: 5px;
}

.parpadea {
  
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}


@media (min-width: 800px) {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }

  .burger {
    display: none;
  }

  header nav ul li {
    display: inline;
    position: relative;
    padding: 8px;
    font-size: 14px;
  }

  nav {
    width: auto;
    display: contents;
  }
  .menu {
    width: auto;
    height: 70px;
  }

  svg#bondi2 {
    margin-left: 0px;
  }

  .abso {
    position: absolute;
    display: block;
    top: 34px;
    right: 198px;
    z-index: 100;
}

.abso2 {
  display: none;
}
  
  .vivo {
    font-size: 10px;
    color: white;
    background-color: red;
    padding: 3px;
    border-radius: 5px;
  }
}

@media (min-width: 1024px) {
  header nav ul li {
    font-size: 1.1rem;
    padding: 10px;
  }

  .isotipo {
    width: 270px;
    height: auto;
  }

  .abso {
    position: absolute;
    display: block;
    top: 35px;
    right: 250px;
    z-index: 100;
  }
  
  .vivo {
    font-size: 12px;
    color: white;
    background-color: red;
    padding: 3px;
    border-radius: 5px;
  }
}
