.esp-cardArticulo {
    width: 95%;
    /*border: solid 1px;*/
    padding: 0 10px;
    margin-bottom: 25px;
}



.esp-cardImage{
    width: 100%;
    height: 320px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    text-align: left;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    transition: 0.2s;
    opacity: 1;
    }

.esp-cardImage:hover {
    opacity: 0.9;
}

    .esp-cardImage h2 {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color:white;
        padding: 8px;
        background-color: #29d579;
        display: inline-block;
    }

.esp-cardText {
        /* color: #333333; */
        text-align: left;
        width: 100%;
        height: 70%;
        /* background: rgb(0,0,0); */
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7987570028011204) 66%, rgba(255,255,255,0) 100%);
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: flex-end;
        padding-right: 10px;
        padding-left: 5px;
        text-align: right;
    }

.esp-cardText h1 {
    font-family: 'Anton', sans-serif;
    line-height: 1;
    font-size: 32px;
    margin-bottom: 3px;
}

.esp-cardText p {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 3px;
}

hr {
    border-top: 1px solid #557951;;
    margin: 5px 0; 
}

.esp-cardText a, a:link, a:visited, a:hover, a:focus, a:active{
    color:inherit;
    text-decoration: none;
  }

@media (min-width: 800px) {
    .esp-cardArticulo {
        width: 24%;
        /*border: solid 1px;*/
        padding: 0 10px;
        margin-bottom: 25px;
    }

    .esp-cardImage{
        width: 100%;
        height: 280px;
        overflow: hidden;
        /* Magic */
        }

        .esp-cardText h1 {
            font-size: 28px;
        
        }
        
    
}

@media (min-width: 1024px) {
    .esp-cardImage{
        height: 340px;
        overflow: hidden;
      
        }

        .esp-cardText h1 {
            font-size: 32px;
        
        }
        
    
}
