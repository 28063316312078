.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 100%;
  background-color: green;
  color: #fff;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: green;
  color: #fff;
}

.indicatorsDots {
  display: flex;
  justify-content: center;
}