header {
  width: 100%;
  height: 70px;
  background: rgb(240, 240, 240);
  color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(224, 224, 224);
  box-shadow: 0px 1px 5px 0px rgba(130, 130, 130, 0.75);
}

.marca {
  height: 70px;
  display: inline-flex;
  font-size: 2.2rem;
  flex-direction: column;
  justify-content: center;
}


h1 {
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: 2.5rem;
}

.navUser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
}

.usermail {
      line-height: 70px;
      font-size: 12px;
      font-family: sans-serif;
      text-align: right;
      font-weight: 700;
}

.navbutton {
  padding: 5px 10px;
  margin: 10px;
  background-color: #29D579;
  color: white;
  font-family: sans-serif;
  font-size: 14px;
  border-radius: 5px;
}

.navbutton:hover {
  background-color: #25ad65;
  cursor: pointer;
}

.burger {
  height: 70px;
  float: right;
  font-size: 1.5rem;
  cursor: pointer;
  padding-right: 10px;
  transition: color 0.5s;
  display: flex;
  align-items: center;
}

.burger :hover {
  color: #29D579;
  transition: color 0.5s;
}

.menu {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(240, 240, 240);
  transition: all 0.5s;
  transform: translateX(200%);
}
.fold {
  transform: translateX(0%);
}
nav {
  width: 100%;
  display: block;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  text-align: center;
}

.menunav {
  vertical-align: middle;
}


.marca a {
  cursor: pointer;
  color: #333333;
}

a:link, a:visited, a:hover, a:focus, a:active{
  color: none;
  text-decoration: none;
}


svg#bondi { 
  width: 43px;
  height: 43px;
  fill: #333;
  padding: 2px;
  margin-left: 10px;
}


@media (min-width: 800px) {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }

  .burger {
    display: none;
  }

  header nav ul li {
    display: inline;
    position: relative;
    padding: 8px;
    font-size: 14px;
  }

  nav {
    width: auto;
    display: contents;
  }
  .menu {
    width: auto;
    height: 70px;
  }

  svg#bondi {
    margin-left: 0px;
  }
}

@media (min-width: 1024px) {
  header nav ul li {
    font-size: 1.1rem;
    padding: 10px;
  }
}
