* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contenedor {
  width: 100%;
  text-align: center;
  min-width: 360px;
}

.none {
  display: none !important;
}

@media (min-width: 1024px) {
  .contenedor {
    width: 95%;
    margin: auto;
  }
}
