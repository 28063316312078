.updtOverlay {
    background-color: rgb(255 255 255 / 70%);;
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    pointer-events:auto;
    transition: all 0.3s;
    z-index: 1000;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .seperator {
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 1001;
    width: 3000px;
    height: 3px;
    animation: rotate 2s infinite linear;
    -webkit-animation: rotate 2s infinite linear;
  }

  @-webkit-keyframes rotate {
    from {
      background-position: -3000px;
    }
    to { 
      background-position: 0px;
    }
  }
  
  @keyframes rotate {
    from {
      background-position: -3000px;
    }
    to { 
      background-position: 0px;
    }
  }
  
  .gradient {
    background: rgb(48,255,144); /* Old browsers */
  background: -moz-linear-gradient(left,  rgba(48,255,144,1) 0%, rgba(237,45,237,1) 25%, rgba(201,152,38,1) 50%, rgba(48,255,230,1) 75%, rgba(48,255,144,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(48,255,144,1)), color-stop(25%,rgba(237,45,237,1)), color-stop(50%,rgba(201,152,38,1)), color-stop(75%,rgba(48,255,230,1)), color-stop(100%,rgba(48,255,144,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); /* IE10+ */
  background: linear-gradient(to right,  rgba(48,255,144,1) 0%,rgba(237,45,237,1) 25%,rgba(201,152,38,1) 50%,rgba(48,255,230,1) 75%,rgba(48,255,144,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30ff90', endColorstr='#30ff90',GradientType=1 ); /* IE6-9 */
  
  }