main {
    margin-top: 70px;
    min-height: 100vh -70px;
}
.listadoArticulos {
    padding-top:40px;
    max-width: 1300px;
    margin: auto;
}

h1.tituloseccion {
    background-color: #369e56;
    color: #e9e9e9;
    font-family: impacted,sans-serif;
    font-size: 50px;
    font-weight: 80;
    line-height: 26px;
    padding-top: 40px;
    text-align: right;

}

@media (min-width: 800px) {
    h1.tituloseccion {  
        line-height: 60px;
        font-size: 100px;
    }
}


@media (min-width: 1024px) {
    h1.tituloseccion {  
        line-height: 80px;
        font-size: 140px;

    }
}