.createUser {
    flex-grow: 0;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 45px;
}

.createUser input {
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
}

.createUser i {
    margin: 0 10px 0 10px;
}

.ver2 {
    display: block;
}