.frames {
 width: 100% !important;
 min-height: 160px !important;
 display: flex;
 margin: auto; 
}

.cabeceraArticulo {
    max-width: 1200px;
    margin:auto;
    text-align: center;
    padding: 0 10px 0 10px;
}

.divisor {
    width: 15%;
    text-align: center;
    margin:auto;
    margin-bottom: 40px;
    margin-top: 30px;
    border-top: 3px solid #29d579;
}

.mainCategory {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding: 5px;
    background-color: #29d579;
    display: inline-block;
    margin: 20px 0 20px;
}

.volanta {
    /*text-transform: uppercase;*/
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 25px;
}

.title {
    font-family: 'Anton', sans-serif;
    line-height: 1;
    text-transform: uppercase;
    font-size: 3.2rem;
    margin: 0 0 25px;
    text-shadow: 0 0 4px white;
}

.bajada {
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        font-size: 16px;
        margin: 0 0 25px;
        line-height: 1.3;
}

.autores {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    margin: 20px;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
span.autor {
    padding-right: 5px;
    font-weight: bold;
    color:black;
}

h4.por{
    color:rgb(54, 185, 11);
    font-weight: bold;
}


.imagenArticulo img {
    width: 100%;
    margin-bottom: 20px;
} 

.textoArticulo {
    max-width: 800px;
    margin:auto;
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    
    padding: 0 10px 0 10px;
}

.textoArticulo p {
    /*margin: 0 0 25px !important;*/
    line-height: 1.5 !important;
    font-size: 16px !important;
}


.textoArticulo a {
    /* margin: 0 0 25px !important; */
    color: #29d579;
    text-decoration: underline !important;
    cursor: pointer;
}

.textoArticulo a:visited {
    /* margin: 0 0 25px !important; */
    color: #369E56;
}


.textoArticulo img {
    width: 100% ;
    height: auto;
}

.textoArticulo ul {
    margin-left:10px;
  }

  .textoArticulo ul li {
    list-style: inside;
  }
  
.finalArt {
    font-size: 16px;
    padding: 0 10px 0 10px;
    margin-top: 20px;
}

.cierre {
        max-width: 800px;
        text-align: center;
        margin:auto;

        border-top: 2px solid #29d579;

}

.fechaArt {
    font-family: 'Raleway', sans-serif;
    max-width: 800px;
    margin:auto;
    text-align: right;
    padding: 5px;
    color:rgb(54, 185, 11);
    font-weight: bold;
}
.catLink {
    font-family: 'Raleway', sans-serif;
    display: inline-block;
    padding: 5px;
    color:rgb(54, 185, 11);
    font-weight: bold;
    cursor: pointer;
    margin: auto;
    cursor: pointer;
    text-align: right;
}


.catLink a {
    color:rgb(54, 185, 11);
}

.catcontainer {
    max-width: 800px;
    margin:auto;
    text-align: right;
}




/*///////////////////////////////////
//////PANTALLA  TABLET /////////////
////////////////////////////////////
 */
 @media (min-width: 800px) {


   .textoArticulo p.miclase {
        font-size: 14px;
    }

    .volanta {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 25px;
    }

    .title {
 
        font-size: 5rem;
 
    }

    .bajada {
        font-size: 18px;
    }

    .autores {
        font-size: 18px;

    }

    .textoArticulo p {
        font-size: 16px !important;
    }

    .finalArt {
        font-size: 20px;
    }
 }


/*///////////////////////////////////
//////PANTALLA DESKTOP /////////////
////////////////////////////////////
 */
 @media (min-width: 1024px) {
    .miclase {
        font-size: 16px !important;
        font-family: 'Roboto', sans-serif !important;
        color:red;
    }

    .cabeceraArticulo {
        max-width: 1200px;
        margin:auto;
        text-align: center;
        padding: 0;
    }
    
    .divisor {
        width: 15%;
        text-align: center;
        margin:auto;
        margin-bottom: 40px;
        margin-top: 30px;
        border-top: 3px solid #29d579;
    }
    
    .mainCategory {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: white;
        padding: 5px;
        display: inline-block;
        margin: 20px 0 20px;
    }
    
    .volanta {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 24px;
        margin: 0 0 25px;
    }
    
    .title {
        font-family: 'Anton', sans-serif;
        line-height: 1;
        text-transform: uppercase;
        font-size: 7.5rem;
        margin: 0 0 25px;
    }
    
    .bajada {
            font-family: 'Raleway', sans-serif;
            font-weight: 500;
            font-size: 24px;
            margin: 0 0 25px;
            line-height: 1.3;
    }
    
    .autores {
        font-size: 24px;
    }
    span.autor {
        padding-right: 5px;
        font-weight: bold;
        color:black;
    }
    
    h4.por{
        color:rgb(54, 185, 11);
        font-weight: bold;
    }
    
    
    .imagenArticulo img {
        width: 100%;
        margin-bottom: 20px;
    }
    
    .textoArticulo {
        max-width: 800px;
        margin:auto;
        text-align: justify;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        padding: 0;
    }
    
    .textoArticulo p {
        /*margin: 0 0 25px !important;*/
        line-height: 1.5 !important;
        font-size: 20px !important;
    }
    
    .finalArt {
        font-size: 20px;
        padding: 0;
    }
    .cierre {
            max-width: 800px;
            text-align: center;
            margin:auto;
    
            border-top: 2px solid #29d579;
    
    }
    
    .fechaArt {
        font-family: 'Raleway', sans-serif;
    
        max-width: 800px;
        margin:auto;
        text-align: right;
        padding: 5px;
        color:rgb(54, 185, 11);
        font-weight: bold;
    }
    .catLink {
        font-family: 'Raleway', sans-serif;
        display: inline-block;
        padding: 5px;
        color:rgb(54, 185, 11);
        font-weight: bold;
        cursor: pointer;
        margin: auto;
        cursor: pointer;
        text-align: right;
    }
    
    
    .catLink a {
        color:rgb(54, 185, 11);
    }
    
    .catcontainer {
        max-width: 800px;
        margin:auto;
        text-align: right;
    }

}

