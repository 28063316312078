main {
    margin-top: 70px;
    min-height: 100vh -70px;
}
.botones {
  display: flex;
  padding: 20px 10px;
    max-width: 1200px;
    margin: auto;
}

.buttonNew2 {
  background-color: #29D579;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonNew2:hover {
  background-color: #25ad65;
  cursor: pointer;
}

.articleEditionContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 10px;
    max-width: 1200px;
    margin: auto;
}


.articleEdition {
  width: 60%;
  min-height: 100vh -70px;
  margin-left: 5px
}

.editor {
  margin-top: 10px;
}

.tox-tinymce {
  border-radius: 5px !important;
}

.articlePreview {
  width: 40%;
  height: 100vh -70px;
  /*border: 1px solid #ccc;*/
  display: flex;
  justify-content: center;
  flex-direction: row;
  float: right;
  min-width: 400px;
  margin-bottom: 0px;

}

.fixed-preview {
  position: sticky;
  top:100px;
  height: 80vh;
  width: 35%;
  max-width: 450px;
  min-width: 400px;
  padding-top: 0px;
  text-align: center;
  word-wrap: break-word;
  overflow: auto;
  padding:2px;
}

.article-form {
display: flex;
flex-direction: column;
width: 95%;
padding-top: 10px;

}


.article-form input {
height: 30px;
margin: 5px 0 10px 0;
border: 1px solid #ccc;
border-radius: 5px;
padding: 5px;
}

.article-form textarea {
  width: 100%;
  margin: 5px 0 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  min-height: 75px;
  height: auto;
  resize: inherit;
  }
  

.article-form select {
  height: 30px;
  margin: 5px 0 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.accionesEdicion {
  display: flex;
}

/*//////////////////////////
///// CSS PREVIEW //////////
//////////////////////////*/
.cabeceraArticulo {
  max-width: 1200px;
  margin:auto;
  text-align: center;
  padding: 0 10px 0 10px;
}

.fixed-preview .divisor {
  width: 15%;
  text-align: center;
  margin:auto;
  margin-bottom: 15px;
  margin-top: 10px;
  border-top: 3px solid #29d579;
}

.fixed-preview .mainCategory {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px;
  background-color: #29d579;
  display: inline-block;
  margin: 20px 0 20px;
}

.fixed-preview .volanta {
  /*text-transform: uppercase;*/
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 16px;
  margin: 0 0 25px;
}


.fixed-preview .title {
  font-family: 'Anton', sans-serif;
  line-height: 1;
  text-transform: uppercase;
  font-size: 3.2rem;
  margin: 0 0 25px;
  text-shadow: 0 0 4px white;
}


.fixed-preview .bajada {
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      font-size: 16px;
      margin: 0 0 25px;
      line-height: 1.3;
}


.fixed-preview .autores {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  margin: 20px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.fixed-preview span.autor {
  padding-right: 5px;
  font-weight: bold;
  color:black;
}


.fixed-preview h4.por{
  color:rgb(54, 185, 11);
  font-weight: bold;
}


.fixed-preview .imagenArticulo {
  width: 100%;
  margin-bottom: 20px;
} 

.fixed-preview .artText {
  width: 95%;
  margin: auto;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  padding: 0 10px 0 10px;
}

.fixed-preview .artText img {
  width: 100% !important;
  height: auto !important;
}

.fixed-preview .artText p {
  /*margin: 0 0 25px !important;*/
  line-height: 1.5 !important;
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
  }

  .fixed-preview .artText p span {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
    }

  .fixed-preview .artText a {
      /* margin: 0 0 25px !important; */
      color: #29d579;
      text-decoration: underline;
      cursor: pointer;
  }

  .fixed-preview .artText a:visited {
    /* margin: 0 0 25px !important; */
    color: #369E56;
}

.fixed-preview .finalArt {
  font-size: 16px;
  padding: 0 10px 0 10px;
}

.fixed-preview .cierre {
      max-width: 800px;
      text-align: center;
      margin:auto;

      border-top: 2px solid #29d579;

}

.fixed-preview ul {
  margin-left:10px;
}

.imgsel {
  display: flex;
}

.imgsel button {
  margin: 5px 0 10px 0;
  padding: 5px 10px 5px 10px;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

#imagenprincipal {
  flex-grow: 1;
  border-radius: 5px 0 0 5px
}
#fileselect {
  display: none;
}

.fixed-preview .fechaArt {
  font-family: 'Raleway', sans-serif;
  max-width: 800px;
  margin:auto;
  text-align: right;
  padding: 5px;
  color:rgb(54, 185, 11);
  font-weight: bold;
}
.fixed-preview .catLink {
  font-family: 'Raleway', sans-serif;
  display: inline-block;
  padding: 5px;
  color:rgb(54, 185, 11);
  font-weight: bold;
  cursor: pointer;
  margin: auto;
  cursor: pointer;
  text-align: right;
}


.fixed-preview .catLink a {
  color:rgb(54, 185, 11);
}

.fixed-preview .catcontainer {
  max-width: 800px;
  margin:auto;
  text-align: right;
}


/*////////////////////////////////////
EDITORES WYSIWYG ////////////////////
////////////////////////////////////*/

.tinymceEditor{
  width: 100%;
  margin: auto;
}
.wrapperClassName{
  border: 1px solid #ccc;
    max-width: 100%;
    padding: 10px;
    margin: 5px auto;
    border-radius: 5px;
  }

#editor {
      max-width: 75%;
      margin: auto;
  }

#editquill {
    margin:auto;
    max-width: 50%;
    height: 400px;
}

/*////////////////////////////////////
EDITORES WYSIWYG ////////////////////
////////////////////////////////////*/


/*////////////////////////////////////
/////// CSS SCROLLBAR PREVIEW /////////

/* width */
.fixed-preview::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.fixed-preview::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.fixed-preview::-webkit-scrollbar-thumb {
  background: rgba(187, 187, 187, 0.507); 
  
  border-radius: 15px;
}

/* Handle on hover */
.fixed-preview::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.555); 
}


/*///////MODAL///////////*/
.modal-contenido{
  background-color:rgb(255, 255, 255);
  min-width:300px;
  max-width: 400px;
  margin: 20% auto;
  position: relative;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  padding-bottom: 1px;
}
.modal{
  background-color: rgb(97 97 97 / 50%);
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity:0;
  pointer-events:none;
  transition: all 0.3s;
  z-index: 1000;
}

.cabeceraModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
 
  color: #111827
  
}

.cabeceraModal h2 {
  font-size: 20px;
  padding: 24px;
}
i.close {
  font-size: 26px;
  padding-right: 24px;
}
.mensajeModal {
  padding: 24px;
  font-size: 16px;
  color:#676767
}

hr.divisorModal {
  margin:0;
  height: 1px;
        background-color: #838383;
        border: none;

}

.buttonModal {    
  background-color: #29d579;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  width: fit-content;
  margin: auto;
  /* margin: 5px; */
  text-align: center;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 20px;
}

.ver {
  opacity:1 !important;
  pointer-events:auto;
}


.defineCarrousel {
  display: flex;
  padding: 0 10px;
  align-items: center;
  padding: 12px 0;
}

.react-switch {
  vertical-align: middle;
  margin-left: 8px;
  
}

@media (max-width: 800px) {
  .articlePreview{
    display: none;
  }

  .articleEdition {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
}

.article-form {
  width: 100%;
  
}

.buttonNew2 {
  background-color: #29D579;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  margin: 5px;
  text-align: center;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonNew2:hover {
  background-color: #25ad65;
  cursor: pointer;
}

}

