footer {
    background-color: #333333;
}

.footer {
    display: flex;
    color: #f0f0f0;
    min-height: 150px;
    /* max-width: 1200px; */
    margin: auto;
    margin-top: 70px;
    padding: 5px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;

}


.contacto-footer {
    text-align: right;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.placido {
    color: #f0f0f0;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.contacto-footer h3 {
    padding: 0;
}

svg#bondi3 {
    color: #f0f0f0;
    margin-left: 5px;
    height: 35px;
    width: 35px;
}

#bondi {
    margin-left: 5px;
    height: 60px;
    width: 60px;
}

#logoplacido {
    height: 60px;
    width: 60px;
    margin-right: 5px;
}


.redes {
    color: #f0f0f0;
    font-size: 11px;
    font-family: 'Raleway', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.rag {
    display: flex;
    color: #838383;
    font-size: 10px;
    text-align: right;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    /* max-width: 120px; */
    /* margin: auto; */
    align-items: center;
}


.rag p {
    padding-bottom: 5px;
    max-width: 70px;
}

.rag img {
    width: 25px;
    height: auto;
    margin:5px;
    filter: grayscale(100%);
}

/*
@media (min-width: 500px) {
    .placido {
        color: #f0f0f0;
        font-size: 14px;
        font-family: 'Raleway', sans-serif;
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .contacto-footer {
        text-align: right;
        font-size: 14px;
        font-family: 'Raleway', sans-serif;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #bondi {
        margin-left: 5px;
        height: 60px;
        width: 60px;
    }

    #logoplacido {
        height: 60px;
        width: 60px;
        margin-right: 5px;
    }
}
*/

@media (min-width: 800px) {
    .footer {
        display: flex;
        color: #f0f0f0;
        height: 100px;
        /*max-width: 1200px;*/
        margin: auto;
        margin-top: 70px;
        padding: 20px;
        flex-direction: row;
        justify-content: space-between;

    }


    .contacto-footer {
        text-align: right;
        font-size: 18px;
    }

    .placido {
        font-size: 18px;

    }

    .redes {
        font-size: 18px;
    }

    svg#bondi3 {
        color: #f0f0f0;
        margin-left: 5px;
        height: 45px;
        width: 45px;
    }

    #bondi {
        margin-left: 5px;
        height: 80px;
        width: 80px;
    }

    #logoplacido {
        height: 80px;
        width: 80px;
    }

    .rag {
        display: flex;
        color: #838383;
        font-size: 14px;
        text-align: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        margin: auto;
    }

    .rag p {
        padding-bottom: 5px;
        max-width:200px;
    }
}



