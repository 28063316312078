.cardArticulo {
    width: 95%;
    /*border: solid 1px;*/
    padding: 0 10px;
    margin-bottom: 25px;
}

 
.cardImage{
    width: 100%;
    height: 300px;
    overflow: hidden;
    /* Magic */
    background-size: cover;
    background-position: center;
    text-align: left;
    margin-bottom: 5px;
    }

.cardImage h2 {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color:white;
        padding: 8px;
        /*background-color: #29d579;*/
        display: inline-block;
    }

.cardText {
    color:#333333;
    text-align: left;
}

.cardText h1 {
    font-family: 'Anton', sans-serif;
    line-height: 1;
    font-size: 28px;
    margin-bottom: 3px;

}

.cardText p {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #557951;
    margin-bottom: 3px;
}

hr {
    border-top: 1px solid #557951;;
    margin: 5px 0; 
}

.cardText a, a:link, a:visited, a:hover, a:focus, a:active{
    color:inherit;
    text-decoration: none;
  }

@media (min-width: 800px) {
    .cardArticulo {
        width: 33%;
        /*border: solid 1px;*/
        padding: 0 10px;
        margin-bottom: 25px;
    }

    .cardImage{
        width: 100%;
        height: 190px;
        overflow: hidden;
        /* Magic */
        }

        .cardText h1 {
            font-size: 28px;
        
        }
        
    
}

@media (min-width: 1024px) {
    .cardImage{
        height: 230px;
        overflow: hidden;
      
        }

        .cardText h1 {
            font-size: 32px;
        
        }
        
    
}
