.quienessomos {
    max-width: 800px;
    margin: auto;
    margin-top: 70px;
    font-family: 'Raleway',sans-serif;
    font-size: 16px;
    padding: 0 10px;
}

.somosCabecera { 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:40px;
    margin-bottom: 20px;
}

.somosTexto {

}

.somosTexto p {
    margin-bottom: 10px;
    line-height: 1.5;
}

.somosCierre {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:20px;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
}

.somosCierre p {
    font-size: 16px;

}


#logovbyn {
 width: 220px;
 height: auto;
 margin-bottom: 10px;
}

.creditos {
    font-family: 'Raleway', sans-serif;
    max-width:800px;
    flex-direction: column;
    margin: auto;
    text-align: center;
}

.creditos h3 {
}

.creditos span {
    display: block;
    margin-top: 15px;
    font-weight: bold;
}

.creditos p {
}

.logorag {
 width: 40px;
 height: auto;
}


@media  (min-width: 800px){

    .quienessomos {
        font-size: 18px;
        padding:0 0;
    }

    .somosCierre p {
        font-size: 18px;
    }

    .creditos {
        font-size: 18px;
    }
}